import request from "../utils/request";

//登录
export function login(data){
    return request({
        url:'/api/v1/wechat/qy/login',
        method:'POST',
        data
    })
}

//获取用户信息
export function getEwxUserInfo(data){
    return request({
        url:'/api/v1/wechat/qy/getEwxUserInfo',
        method:'POST',
        data
    })
}

//绑定企微账户
export function bind(data){
    return request({
        url:'/api/v1/wechat/qy/bind',
        method:'POST',
        data
    })
}

//解绑企微账户
export function unbind(data){
    return request({
        url:'/api/v1/wechat/qy/unbind',
        method:'POST',
        data
    })
}

//获取asstoken
export function getAsstoken(data){
  return request({
   url:'/api/v1/wechat/getAsstoken',
   method:'POST',
   data
  })
}
//获取Ticket
export function getTicket(data){
  return request({
   url:'/api/v1/wechat/qy/getTicket',
   method:'POST',
   data
  })
}
//获取JsapiTicket
export function getJsapiTicket(data){
   return request({
    url:'/api/v1/wechat/qy/getJsapiTicket',
    method:'POST',
    data
   })
}
//获取报名记录
export function getAppList(data){
  return request({
   url:'/api/v1/wechat/qy/getAppList',
   method:'POST',
   data
  })
}
//获取打卡记录
 export function getClockonList(data){
  return request({
   url:'/api/v1/wechat/qy/getClockonList',
   method:'POST',
   data
  })
}
//获取企业员工是否绑定内部账号
export function getOusbandStatus(data){
  return request({
   url:'/api/v1/wechat/qy/getOusbandStatus',
   method:'POST',
   data
  })
}
//绑定企业微信user_id
export function setOusband(data){
  return request({
   url:'/api/v1/wechat/qy/setOusband',
   method:'POST',
   data
  })
}
//获取客户的信息
export function getKehuUserId(data){
  return request({
   url:'/api/v1/wechat/qy/getKehuUserId',
   method:'POST',
   data
  })
}
//根据code获取个人信息
export function getMyUserId(data){
  return request({
      url: `/api/v1/wechat/qy/getMyUserId?code=${data.code}`,
      method: 'GET',
  })
}
//获取企业微信APPID
export function getQyConfigStr(data){
  return request({
   url:'/api/v1/wechat/qy/getQyConfigStr',
   method:'POST',
   data
  })
}
//获取订单列表
export function getOrderList(data){
    return request({
        url:'/api/v1/wechat/qy/getOrderList',
        method:'POST',
        data
    })
}
//获取订单key
export function getStorePositionKey(data){
    return request({
        url:'/api/v1/wechat/qy/getStorePositionKey',
        method:'POST',
        data
    })
}
//获取我的会员数据
export function getKehuDetail(data){
    return request({
        url:'/api/v1/wechat/qy/getKehuDetail',
        method:'POST',
        data
    })
}
//回访
export function setVisit(data){
    return request({
        url:'/api/v1/wechat/qy/setVisit',
        method:'POST',
        data
    })
}
//获取月份
export function getYearMonth(data){
    return request({
        url:'/api/v1/wechat/qy/getYearMonth',
        method:'POST',
        data
    })
}
// 报名详情
export function getApplets(data){
    return request({
        url:'/api/v1/wechat/qy/applets_detail',
        method:'POST',
        data
    })
}
// 聊天列表(暂时作废)
export function getChatList(data){
    return request({
        url:'/api/v1/wechat/qy/work_wechat_chat/list',
        method:'POST',
        data
    })
}
// 通用上传
export const uploadFile = (data) => {
    return request({
       url: '/api/upload/uploadfile',
       method: 'post',
       data
    })
 }
 // 获取所有的标签
export const tagList = (data) => {
    return request({
       method: 'post',
       url: '/api/v1/wechat/qy/tag_list',
       data
    })
 }
//  企微应用菜单-客户标签绑定
 export const bindTagUser = (data) => {
    return request({
       url: '/api/v1/wechat/qy/bind_tag_user',
       method: 'post',
       data
    })
 }
//  企微应用菜单-编辑备注
 export const editRemarks = (data) => {
    return request({
       url: '/api/v1/wechat/qy/edit_remarks',
       method: 'post',
       data
    })
 }
 // 订单-明日订单列表
 export const nexDayList = (data) => {
    return request({
       url: '/api/v1/wechat/qy/nex_day_list',
       method: 'post',
       data,
    })
 }
//  订单详情 
 export const positionInfo = (data) => {
    return request({
       url: '/api/v1/wechat/qy/position_info',
       method: 'post',
       data,
    })
 }
 //  识别身份证号码 
 export const ocrIdcard = (data) => {
    return request({
       url: '/api/v1/wechat/ocr_idcard',
       method: 'post',
       data,
    })
 }

  //  获取岗位加密key 
export const getPositionKey = (data) => {
    return request({
       url: '/api/v1/wechat/qy/get_position_key',
       method: 'post',
       data,
    })
 }
   //  订单-帮会员报名
export const qyToolHelpEnroll = (data) => {
    return request({
       url: '/api/v1/wechat/qy/qy_tool_help_enroll',
       method: 'post',
       data,
    })
 }
    //  订单-帮会员报名(判断是否需要显示会员来源)
export const hasMemberSource = (data) => {
    return request({
       url: '/api/v1/wechat/qy/has_member_source',
       method: 'post',
       data,
    })
 }
     // 聊天列表
export const chatList = (data) => {
    return request({
       url: '/api/v1/wechat/qy/work_wechat_chat/chat_list',
       method: 'post',
       data,
    })
 }
      // ai信息答复
export const aiSearch = (data) => {
    return request({
       url: '/api/v1/wechat/qy/work_wechat_chat/ai_search',
       method: 'post',
       data,
    })
 }
       // 轮询时间
export const pollingTime = (data) => {
    return request({
       url: '/api/v1/wechat/qy/work_wechat_chat/polling_time',
       method: 'post',
       data,
    })
 }
// ai助手发送消息
export const qyChatRequest = (data) => {
    return request({
        url: '/api/v1/wechat/qy/assistant_request',
        method: 'post',
        data,
    })
}
// ai助手发送消息列表
export const qyChatAssistantList = (data) => {
    return request({
        url: '/api/v1/wechat/qy/assistant_list',
        method: 'post',
        data,
    })
}
// 企微工具栏点赞与点踩
export const qyAnswerLike = (data) => {
    return request({
        url: '/api/v1/wechat/qy/answer_like',
        method: 'post',
        data,
    })
}
// 企微工具栏编辑答案
export const qyAnswerUpdate = (data) => {
    return request({
        url: '/api/v1/wechat/qy/answer_update',
        method: 'post',
        data,
    })
}
 


// 企微工具栏获取经纪人权限
export const GetBrokerEnrollCheck = (data) => {
    return request({
        url: '/api/v1/wechat/qy/qy_tool_help_enroll_check',
        data,
        method: 'post'
    })
} 
 
